@use "../4-helpers/" as *;

.login-form {
  margin-top: 2rem;
  // max-height: 30%;
  height: 100%;
}

.create-account-btn {
  color: #ffffff !important;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 700;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;

  &.enabled {
    background-color: #4120ce !important;
  }
}

.create-account-btn:hover {
  background-color: #4120ce;
}

.recovery-modal-form {
  width: 25rem;
}

.general-form {
  width: 100%;
  padding: 2rem;
}

.alert-form {
  width: 100%;
  padding-top: 2rem;
}

.form-content-component {
  display: flex;
  gap: 2rem;
  flex-direction: column;
}

.user-profile-form {
  width: 100%;
  padding: 2rem;
}

.c-input {
  width: 100%;
  padding-top: 1.2rem;

  &--padding-bottom {
    padding-bottom: 1.6rem;
  }
}

.label {
  display: block;
  margin-bottom: 0.4rem;
  color: #292929 !important;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 400;
}

.input {
  width: 100%;
  height: 4rem;
  border: 0.1rem solid $color-neutral-light;
  border-radius: 0.4rem;
  padding: 0.7rem 0.8rem;
  @include themed() {
    background-color: t("input-bg");
    color: t("primary-text");
  }

  &--area {
    height: 16rem;
  }

  &--password {
    padding-right: 4.5rem;
  }

  &:focus {
    outline: none;
    @include themed() {
      border: 0.1rem solid t("input-focus");
    }
  }

  &:disabled {
    @include themed() {
      background-color: t("input-disabled-bg");
    }
  }

  &--error,
  &--error:focus {
    outline: none;
    border: 0.1rem solid $color-warning-base;
  }
}

.c-login-content .input::placeholder {
  color: #666666;
  font-size: 1.5rem;
  line-height: 2rem;
}

.login-btn-link {
  font-size: 1.5rem;
  line-height: 2rem;
}

.MuiAutocomplete-listbox {
  padding: 0;
}

.MuiAutocomplete-paper {
  border-radius: 0.4rem;
  @include themed() {
    background-color: t("primary-bg");
    color: t("primary-text");
    border: 0.1rem solid t("primary-border");
    box-shadow: 0.4rem 0.4rem 0.8rem rgba(t("box-shadow"), 0.1);
  }
}

.MuiAutocomplete-option {
  height: 4.6rem;
  padding: 1.2rem 1.6rem;

  &:hover {
    @include themed() {
      background-color: t("menu-hover");
    }
  }
}

.MuiAutocomplete-option[aria-selected="true"] {
  @include themed() {
    background-color: t("menu-selected");
  }
}

.MuiAutocomplete-option[aria-selected="true"].Mui-focused {
  @include themed() {
    background-color: t("menu-selected");
  }

  &:hover {
    @include themed() {
      background-color: t("menu-hover");
    }
  }
}

.select {
  width: 100%;
  height: 4rem;
  border-radius: 0.4rem;
  @include themed() {
    color: t("input-label");

    &:hover .MuiOutlinedInput-notchedOutline {
      border: 0.1rem solid t("input-focus");
    }
  }

  &--error {
    width: 100%;
    height: 4rem;
    border-radius: 0.4rem;

    .MuiOutlinedInput-notchedOutline {
      border: 0.1rem solid $color-warning-base;
    }

    &:hover .MuiOutlinedInput-notchedOutline {
      border: 0.1rem solid $color-warning-base;
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border: 0.1rem solid $color-warning-base;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border: 0.1rem solid $color-neutral-light;
  }

  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 0.1rem solid $color-primary-base;
  }
}

//remove padding from dropdown menu from material-ui
.MuiMenu-list {
  padding: 0;
}

.c-checkbox {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-top: 2rem;
}

.checkbox-label {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  font-size: 1.5rem;
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.login-checkbox {
  margin: 0;
  padding: 0;
  display: flex;
}

.login-create-account {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 0.5rem;
  width: 100%;
  font-size: 1.5rem;
  text-align: center;
}

.c-error-text {
  display: flex;
  align-items: center;
  height: 1.6rem;
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  &--hidden {
    visibility: hidden;
    height: 0rem;
    margin-top: 0rem;
  }
}

.error-text {
  color: $color-warning-base;
}

.c-error-message {
  display: flex;
  align-items: center;
  padding: 0.4rem;
  border-radius: 0.4rem;
  background-color: $color-warning-light;

  &--block {
    display: block;
    margin-top: 1.6rem;
  }

  &--line {
    display: flex;
    align-items: center;
  }
}

.input-search {
  max-width: 20rem;
  min-width: 20rem;
  height: 4rem;
  border-radius: 0.4rem;
  z-index: 1;
  width: 1rem;
  padding-left: 3rem;

  @include themed() {
    background-color: t("tb-bg");
    border: 0.1rem solid t("tb-search-input-border");
    color: t("tb-text");
  }

  &--hidden {
    visibility: hidden;
  }

  &:focus {
    outline: none;
    border: 0.1rem solid $color-primary-base;
  }
}

.c-form-page {
  display: flex;
  flex-direction: column;
}

.c-form-btn {
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
}

.c-form-full-width-btn {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2.5rem;
}

.c-form-tabs {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 0.1rem solid $color-neutral-light;
  height: 8rem;
  padding: 0;
}

.c-form-tab-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.form-tab-btn {
  padding: 0 1rem;
  margin: 0;
  height: 8rem;
  @include themed() {
    color: t("form-tab-text");
  }

  .MuiStepLabel-iconContainer {
    padding-right: 0.2rem;
  }

  .MuiStepLabel-root {
    align-items: flex-end;
  }

  .MuiStepLabel-label {
    line-height: normal;
    @include themed() {
      color: t("form-tab-text");
    }
  }

  &--active {
    @include themed() {
      color: t("form-tab-active");
      border-bottom: 0.2rem solid t("form-tab-active");
      .MuiStepLabel-label {
        color: t("form-tab-active");
      }
    }
  }
}

.skeleton-form-input {
  height: 4rem;
  border-radius: 0.4rem;
}

.skeleton-form-label {
  height: 1.6rem;
  width: 30%;
  margin-bottom: 0.4rem;
  border-radius: 0.4rem;
}

.skeleton-form-btn {
  height: 4.8rem;
  width: 16rem;
  border-radius: 0.4rem;
}

.c-map-input {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1rem;

  @include themed() {
    background-color: t("primary-bg");
  }
}

.c-map-input-content {
  position: relative;
  width: 100%;
  height: 63rem;
}

.c-map-input-error {
  display: flex;
  justify-content: space-around;
  height: 100%;

  .error-page-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.c-map-calendar-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 34.3rem;
  height: 40.1rem;
}

.c-map-error {
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-radio-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 1.2rem 0.5rem 0;
  padding: 0.5rem 1rem 0.5rem 2rem;
  border-radius: 5rem;
  border: 0.1rem solid $color-neutral-light;

  &--checked {
    background-color: $color-primary-base;
    color: $color-neutral-lightest;
  }

  &--very-high-risk {
    border: 0.1rem solid #ac2bfb;
  }

  &--checked-vh-risk {
    background-color: #ac2bfb;
    color: $color-neutral-lightest;
  }

  &--high-risk {
    border: 0.1rem solid #fc5a5a;
  }

  &--checked-high-risk {
    background-color: #fc5a5a;
    color: $color-neutral-lightest;
  }

  &--medium-risk {
    border: 0.1rem solid #f39c11;
  }

  &--checked-medium-risk {
    background-color: #f39c11;
    color: $color-neutral-lightest;
  }

  &--low-risk {
    border: 0.1rem solid $color-helper-base;
  }

  &--checked-low-risk {
    background-color: $color-helper-base;
    color: $color-neutral-lightest;
  }

  .MuiRadio-root {
    padding: 0;
    padding-right: 0.9rem;
  }
}

.c-range-slider {
  display: flex;
  flex-direction: row;
  width: 70%;
  padding: 3.2rem 0;
}

.range-slider-input {
  height: 4rem;
  border: 0.1rem solid $color-neutral-light;
  border-radius: 0.4rem;
  padding: 0.7rem 0.8rem;
  @include themed() {
    background-color: t("input-bg");
    color: t("primary-text");
  }

  &::-webkit-inner-spin-button {
    opacity: 1;
  }

  &:focus {
    outline: none;
    @include themed() {
      border: 0.1rem solid t("input-focus");
    }
  }

  &:disabled {
    @include themed() {
      background-color: t("input-disabled-bg");
    }
  }

  &--error,
  &--error:focus {
    outline: none;
    border: 0.1rem solid $color-warning-base;
  }
}

.range-slider {
  margin: 0 3.2rem;
  height: 1.2rem;

  .MuiSlider-rail {
    background-color: $color-primary-base;
    opacity: 0.2;
  }

  .MuiSlider-track {
    background-color: $color-primary-base;
  }

  .MuiSlider-thumb {
    background-color: $color-neutral-lightest;
  }

  .MuiSlider-valueLabel {
    background-color: $color-primary-base;
    color: $color-neutral-lightest;
  }
}

.c-multi-form {
  width: 100%;
  border-radius: 0.4rem;
  box-shadow: none;

  .MuiCollapse-root {
    visibility: visible;
  }

  &.Mui-expanded {
    min-height: 0;
    margin: 0;
  }

  &:before {
    height: 0;
  }

  &:not(:last-child) {
    margin-bottom: 1.6rem;
  }
}

.multi-form-title {
  color: $color-primary-base;
}

.multi-form-summary {
  border-radius: 0.4rem;
  border: 0.1rem solid $color-primary-base;
  min-height: 0;

  &.Mui-expanded {
    min-height: 0;
  }
}

.multi-form-content {
  margin: 0;
  display: flex;
  align-items: center;
  min-height: 5rem;

  &.Mui-expanded {
    margin: 0;
  }
}

.skeleton-map-input {
  width: 100%;
  height: 100%;
  border-radius: 0.4rem;
}

.c-text-counter {
  margin: 0.3rem 0;
}

.form-table {
  padding: 2rem 0 4.5rem 0;
  min-width: 4rem;
  width: 100%;
}

.form-alert-table {
  padding: 0 0 4.5rem 0;
  // min-width: 4rem;
  display: flex;
  align-items: center;
  width: 100%;
}

.form-rain-table {
  min-width: 4rem;
  width: 100%;
}

.form-content {
  display: flex;
  justify-content: space-between;

  gap: 1rem;
  width: 100%;
  margin-bottom: 1rem;

  align-items: center;
}

.content-form {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.content-form-Export {
  display: flex;
  align-items: center;
}

.content-form-MultiAction {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding-left: 1rem;
  width: 20.4rem;
}
.content-header-forecast {
  display: flex;
  justify-content: normal;
  align-items: center;
  width: 20rem;
  margin-bottom: 1rem;
  padding-right: 1rem;
}
// Add this standardized form header class
.content-form-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  margin-bottom: 1rem;
  padding: 0 1rem;

  .btn-table-export,
  .btn-table-search,
  .hider-btn,
  .new-table-item-btn {
    min-width: fit-content;
    white-space: nowrap;
  }
}
.content-form-forecast {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.content-form-rain-report {
  display: flex;
  align-items: center;
  margin-right: 1rem;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0.8rem;
}

.rain-report-text-filter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.4rem;
  margin-top: 2.4rem;
  margin-bottom: -3rem;
  padding: 0.3rem;
  width: auto;
  p {
    background-color: rgba(213, 205, 244, 0.5);
    color: #292929;
    font-size: 1.2rem;
    font-weight: normal;
    line-height: 120%;
    padding: 0.5rem;
    border-radius: 2px;
  }
}

.c-input-container {
  width: 65%;
}

.input-controls {
  width: 54%;
  padding-top: 1.2rem;
}

.c-input-text {
  width: 80rem;
}

.container-input {
  width: 100%;
  margin-bottom: 1rem;
}

.content-header-user {
  display: flex;
  justify-content: flex-end;

  width: 100%;

  margin-bottom: 1rem;

  align-items: center;

  padding-right: 1rem;
}

.text-header-calendar {
  color: var(--Cittua-Neutral-Cittua---Neutral---Darkest, #292929);
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;

  margin-right: 0.5rem;

  margin-bottom: 3.2rem;
}

.icon--close-modal-map {
  color: #292929;
}
.modal-legend-wind {
  .scale-title {
    margin-bottom: 2.6rem;
    color: #777777;
  }
}

.rain-fall-legend {
  width: 37rem;
  font-size: 1.4rem;
  height: 10rem;
}

.wind-legend {
  display: flex;
  gap: 4rem;
  align-items: center;

  margin-bottom: 8.3rem;
}

.c-map-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: self-start;
  width: 45rem;
  height: 36.1rem;
}

.legend {
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
  text-align: center;
  p {
    font-size: 1.4rem;
  }
}

.text-header-legend {
  color: var(--Cittua-Neutral-Cittua---Neutral---Darkest, #292929);
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;

  margin-right: 3rem;
}

.text-legend {
  color: #777777 !important;
}

.custom-level-class {
  font-family: "Inter-Regular", sans-serif;
  font-size: 1.4rem;

  margin-top: 1.2rem;
}

.map-calendar-button {
  margin-left: -0.6rem;
  margin-top: -0.4rem;

  height: 4.2rem;
}

.map-legend-button {
  margin-top: -0.5rem;
  height: 4.2rem;
}

.c-error-text-map {
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.details-item {
  font-family: $font-primary-regular;
  font-size: 1.6rem;
  color: #4120ce !important;
  font-weight: 700;
  line-height: 100%;
  margin-bottom: 1rem;
}

.details-item-secondary {
  margin-bottom: 2.5rem;
  font-size: 1.6rem;
  line-height: 160%;
  color: #292929 !important;
}

.c-map-input {
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }
}

.form-time-input {
  width: 10rem;
  padding-top: 1.2rem;
  display: flex;
  justify-content: left;

  input {
    font-size: 1.5rem;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
  }

  @media (max-width: 768px) {
    width: 50%;
  }
}

.c-time-input .MuiInputBase-root {
  margin-left: 0 !important;
}

.time-group {
  margin-top: 2rem;
  padding-left: 0;
}

.map-time-group {
  display: flex;
  flex-direction: row;
  width: 100% !important;
  justify-content: left !important;
  align-items: center;
  padding-left: 0;
  padding-right: 0;
  gap: 3rem;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 0;
    width: 84% !important;
    margin-top: 1.5rem;
  }
}

.time-input-label {
  flex-grow: 1;
  white-space: nowrap;
  margin-top: 1rem;
  color: #4120ce !important;
  font-weight: 600;

  @media (max-width: 768px) {
    width: 100% !important;
    flex-grow: 0;
  }
}

.map-time-input {
  padding-top: 1.2rem;
  width: 100% !important;

  @media (max-width: 768px) {
    width: 100% !important;
  }

  input {
    font-size: 1.5rem;
    width: 10rem;

    @media (max-width: 1200px) {
      width: 15rem;
    }

    @media (max-width: 768px) {
      width: 80% !important;
    }
  }
}

.alert-title {
  width: 50% !important;

  @media (max-width: 768px) {
    width: 100% !important;
  }
}

.alert-text-area textarea {
  width: 60% !important;

  @media (max-width: 768px) {
    width: 100% !important;
  }
}

.c-form-btn {
  width: 10% !important;

  @media (max-width: 1366px) {
    width: 20% !important;
  }

  @media (max-width: 768px) {
    width: 100% !important;
  }
}

.post-alert-label {
  color: #4120ce !important;
}
