.user-area-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

/* Modal Content */
.modal-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px 40px;
  text-align: center;
  position: relative;
  width: 440px;
}

/* Close Button */
.close-btn {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 25px;
  color: #333;
  cursor: pointer;
}

.close-btn:hover {
  color: #000;
}

/* Icon */
.modal-icon {
  width: 64px;
  height: 64px;
  background-color: #f3ecfe;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 16px auto;
}

.modal-icon img {
  width: 32px;
  height: 32px;
}

/* Modal Text */
.modal-title {
  font-size: 24px;
  margin: 10px 0 25px;
  color: #333;
  font-weight: bold;
}

.modal-description {
  font-size: 14px;
  color: #292929;
  margin: 0 0 30px;
}

/* Buttons */
.modal-buttons {
  display: flex;
  gap: 8px;
  flex-direction: column;
}

.delete-btn {
  background-color: #4120CE;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
}

.delete-btn:hover {
  background-color: #3600b3;
}

.cancel-btn {
  background-color: transparent;
  color: #4b00ff;
  padding: 10px;
  border: 2px solid #4b00ff;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
}

.cancel-btn:hover {
  background-color: #f3ecfe;
}