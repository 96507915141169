.edit-user-btn {
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background-color: #4120ce;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  text-decoration: none;
  width: 100%;
  margin-top: 20px;
}

.edit-user-btn-prev {
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #4120ce;
  background-color: #fff;
  border: #4120ce 2px solid;
  border-radius: 6px;
  cursor: pointer;
  text-decoration: none;
  width: 100%;
  margin-top: 20px;
}
