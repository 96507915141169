@use "../4-helpers" as *;
@use "../2-components/icons" as *;
.c-sidebar {
  display: flex;
  flex-direction: row;
  transition: width 0.3s ease;

  &__logo {
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      transition: all 0.3s ease;
      max-width: 100%;
      height: auto;
    }
  }
}

.sidebar {
  display: flex;
  transition: width 0.3s ease;
  @include themed() {
    background-color: t("sidebar-bg");
  }

  background-image: url("../../assets/icons/cittua-symbol.svg");
  background-repeat: no-repeat;
  background-position: -11.8rem 65vh;
  overflow-x: hidden;
  border: 0;

  &--open {
    width: 24rem;
    transition: all 0.3s ease;

    .c-sidebar__main-menu {
      padding: 1.6rem 1.2rem 0.8rem 1.2rem;
    }

    .c-sidebar__sub-menu {
      padding: 0.8rem 1.2rem;
    }

    .sidebar__divider-margin {
      margin: 0 2.6rem;
    }
  }
  &--closed {
    width: 8.1rem;
    transition: all 0.3s ease;

    .c-sidebar__main-menu {
      padding: 0.8rem 0.3rem;
    }

    .c-sidebar__sub-menu {
      padding: 0.8rem 0.3rem;
    }

    .sidebar-item__text,
    .sidebar-item__secondary-icon,
    .MuiAccordionSummary-expandIconWrapper,
    .MuiListItemIcon-root,
    .icon--sidebar[svg*="Chevron"] {
      display: none;
    }

    .c-sidebar__logo {
      padding: 2rem 1rem;
    }
  }
  &__logo {
    height: 3.7rem;
  }

  &__divider {
    border-color: $color-neutral-light;
  }

  &::-webkit-scrollbar {
    width: 1.1rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-neutral-dark;
    border-radius: 10rem;
  }

  &::-webkit-scrollbar-track {
    background-color: $color-neutral-light;
    border-radius: 10rem;
  }
}

.sidebar-item {
  height: 4rem;
  transition: all 0.3s ease;

  &__content {
    white-space: nowrap;
    overflow: hidden;
  }

  &.Mui-selected,
  &:hover,
  &.Mui-selected:hover {
    background-color: $color-primary-base;
    border-radius: 0.4rem;

    .sidebar-item__text {
      color: $color-neutral-lightest;
    }

    .icon--sidebar {
      color: $color-neutral-lightest;
    }

    .icon--sidebar--chevron {
      color: $color-neutral-lightest;
    }
  }

  &__text {
    @include themed() {
      color: t("sidebar-text");
    }

    white-space: nowrap;

    &--hidden {
      visibility: hidden;
    }
  }

  &__icon {
    width: 4rem;
    height: 4rem;

    background-color: transparent;
  }

  &__secondary-icon {
    min-width: 0;
  }

  &__icon-wrapper {
    display: flex;
    min-width: 0;
    align-items: center;
    justify-content: center;
  }
}

.c-sidebar-item:not(:last-child) {
  padding-bottom: 0.8rem;
}

.sidebar-sub-item {
  height: 4.8rem;
  padding-left: 3rem;
  background-color: $color-neutral-lightest;

  &:hover {
    background-color: $color-neutral-lightest;

    .sidebar-item__text {
      color: $color-primary-base;
    }
  }

  &.Mui-selected,
  &.Mui-selected:hover {
    background-color: $color-neutral-lightest;

    .sidebar-item__text {
      color: $color-primary-base;
    }
  }
}

.skeleton-sidebar-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 4.8rem;
  padding-left: 2rem;

  .MuiSkeleton-wave::after {
    background: linear-gradient(
      90deg,
      transparent,
      rgba(0, 0, 0, 0.2),
      transparent
    );
  }

  &--icon {
    width: 4rem;
    height: 4rem;
    min-width: 4rem;
    border-radius: 50%;
  }

  &--txt {
    width: 14rem;
    height: 4rem;
    margin-left: 1.8rem;
  }
}
