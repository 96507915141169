/* Card Styling */
.personal-info-card {
  background-color: #fff;
  padding: 30px 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}

/* Title & Subtitle */
.title-account {
  margin: 0 0 10px;
  font-size: 1.5rem;
  font-weight: 700;
  color: #000;
}

.info-account-icon {
  width: 35px;
  height: 35px;
  background-color: #EDEAFA;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 3px;
}

.subtitle-account {
  color: black;
  font-weight: 400;
  font-size: 16px;
}

/* Info Items */
.info-item {
  display: flex;
  align-items: center;
  margin: 10px 0;
  font-size: 0.95rem;
  color: #333;
}

.info-item strong {
  margin: 0 5px;
  font-size: 16px;
  font-weight: 700;
}

.info-item span {
  margin: 0 5px;
  font-size: 16px;
}

/* Edit Button */
.edit-button {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  background-color: #4120CE;
  color: #fff;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
}

.edit-button:hover {
  background-color: #4a14c8;
}