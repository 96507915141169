General reset * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;
}

body {
  font-family: Arial, sans-serif;
  color: #333;
  background-color: #f9f9f9;
}

.app-container {
  display: flex;
  min-height: 100vh;
}
/* Sidebar styles */
.sidebar {
  position: fixed;
  height: 100vh;
  z-index: 1000;
  background-color: #fff;
  transition: transform 0.3s ease-in-out;
  width: 250px;
  left: 0;
  top: 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.header {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  height: 80px;
  padding: 0 25px;
  border-bottom: 1px solid #e9ecef;
}
.logo-container {
  display: flex;
  align-items: center;
  height: 100%;
}

/* Ensure main content adjusts when sidebar is open */
.main-content {
  transition: margin-left 0.3s ease-in-out;
  width: 100%;
}

/* Update sidebar styles */
.sidebar {
  position: fixed;
  height: 100vh;
  z-index: 99999;
  background-color: #fff;
  transition: transform 0.3s ease-in-out;
  width: 250px;
  left: 0;
  top: 0;
}

/* Media queries for responsive design */
@media screen and (min-width: 769px) {
  .sidebar {
    position: relative;
    transform: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .sidebar-mobile-menu {
    display: none;
  }

  .app-container {
    display: flex;
  }

  .main-content {
    flex: 1;
  }
}

@media screen and (max-width: 768px) {
  .app-container nav {
    margin-top: 10px;
  }

  .sidebar-mobile-menu {
    display: block;
  }

  .sidebar-mobile-menu button {
    margin-left: 17px;
  }

  .app-container .sidebar {
    transform: translateX(-100%);
  }

  .sidebar {
    transform: translateX(-100%);
  }

  .sidebar.open {
    transform: translateX(0);
  }
}
.logo {
  height: 40px;
  width: auto;
  padding: 0;
}

.hamburger-menu {
  border: none;
  background: none;
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
  margin-right: 2rem;
}

.app-container > aside > nav {
  width: 100%;
  padding: 10px 10px 0 8px;
}

.app-container nav ul {
  list-style: none;
  padding: 15px 0 0 0;
}

.app-container nav ul li {
  font-size: 18px;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 0;
  text-decoration: none;
}

.app-container nav ul li a.menu-link {
  display: flex;
  gap: 10px;
  align-items: center;
  text-decoration: none;
  color: #8a92a6;
  padding: 12px 24px;
  width: 100%;
  transition: all 0.2s ease;
}

.app-container nav ul li a {
  display: flex;
  gap: 10px;
  align-items: center;
  text-decoration: none;
  color: #8a92a6;
}

.app-container nav ul li a svg {
  color: #8a92a6;
}

.app-container .active a.menu-link {
  background-color: #623ce4;
  color: #fff;
  border-radius: 16px;
}

.app-container .active a.menu-link svg {
  color: #fff;
}

.app-container nav ul li:last-child a.menu-link:hover {
  background-color: #e7a4a4;
  border-radius: 16px;
}

.menu-link svg {
  min-height: 20px;
  min-width: 20px;
}
.app-container nav ul li:hover {
  background-color: #d5cdf4;
  border-radius: 16px;
}

.app-container nav ul li:hover:not(:last-child) a {
  color: #623ce4;
  background-color: #d5cdf4;
  border-radius: 16px;
}

.app-container nav ul li:hover:not(:last-child) img {
  filter: invert(23%) sepia(87%) saturate(3564%) hue-rotate(248deg)
    brightness(91%) contrast(97%);
}

.app-container ul li:last-child a.menu-link,
.app-container ul li:last-child a.menu-link svg {
  color: #db1a17;
}

.app-container ul li img {
  width: 20px;
  height: 20px;
  fill: #623ce4;
}

.app-container nav ul li:hover:last-child {
  background-color: #e7a4a4;
  border-radius: 16px;
}

.app-container nav ul li:hover:last-child svg {
  color: #db1a17;
}

.sidebar-breakpoint {
  border: 1px solid #e9ecef;
  margin: 0 0 10px 0;
}

/* Main content styles */
.main-content {
  flex: 1;
  background-color: #f1eeff;
}

.page-title {
  padding: 15px 25px;
  font-weight: 500 !important;
  color: #4120ce;
  font-size: 2rem;
}

header h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.content-container {
  display: flex;
  background-color: #ffffff;
  margin: 0 25px 25px 25px;
  border-radius: 8px;
  justify-content: center;
  min-height: calc(100% - 150px);
}

.content {
  padding: 40px;
  width: 80%;
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.info-locations-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  width: 100%;
}

.info-locations-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 0 auto;

  @media (min-width: 1000px) {
    max-width: 800px;
  }
}

.badge-number {
  background-color: #4120ce;
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  font-size: 18px;
}

.info-locations-header {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #f1eeff;
  color: #4120ce;
  border-radius: 24px 8px 8px 24px;
  & p {
    margin-left: 15px;
    font-size: 16px;
  }
}

.locations-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.intro {
  margin-top: 30px;
}

.intro-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.intro span {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 16px;
}

.intro svg {
  margin-right: 10px;
}

.intro p {
  font-size: 14px;
  font-weight: 500;
  color: #4120ce;
}

.intro-highlight h4 {
  color: #4120ce;
  font-weight: 500;
  font-size: 3rem;
  margin: 2rem 0;
}

.userLocal-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  margin-bottom: 30px;
}

.userLocal-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.userLocal-buttons button,
.userLocal-buttons a {
  padding: 7px 10px;
  border: none;
  border-radius: 5px;
  background-color: #edeafa;
  color: #4120ce;
  cursor: pointer;
  text-decoration: none;
}

.add-location-btn {
  display: flex;
  justify-content: center;
  padding: 20px 20px;
  font-size: 16px;
  color: #5a5463;
  border-radius: 10px;
  margin-bottom: 40px;
  cursor: pointer;
  width: 100%;
  border: none;
  background-color: #edeafa;
}

.add-location-btn svg {
  color: #9583e4;
  margin-right: 10px;
}

.card {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 30px 30px 0px rgba(0, 0, 0, 0.1);
  width: 330px;
  height: 339px;
  align-self: center;
}

.card h3 {
  font-size: 18px;
  color: #623ce4;
  margin-bottom: 10px;
}

.card p {
  font-size: 14px;
  margin-bottom: 20px;
}

.image-placeholder {
  display: flex;
  justify-content: center;
  width: 100%;
}

.image-placeholder > svg {
  height: 200px;
  width: 200px;
}

.features {
  padding: 20px;
  box-shadow: -6px 6px 8px rgba(0, 0, 0, 0.18);
  margin: 25px 0;
  border-radius: 16px;
  width: 50%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.features svg {
  color: #4120ce;
  margin-right: 0;
}

.next-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  color: #4120ce;
  font-size: 14px;
}

.next-btn svg {
  margin-left: 10px;
}

.prev-btn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  color: #4120ce;
  font-size: 14px;
}

.prev-btn svg {
  margin-right: 10px;
}

.dashed-card {
  border: 1px dashed #4120ce;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #fff;
  color: #4120ce;
  margin: 3rem 0;
}

.download-app-card {
  display: flex;
  flex-direction: row;
  background-color: #f1eeff;
  margin: 1rem 0 3rem 0;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 30px 30px 0px rgba(0, 0, 0, 0.1);
  justify-content: space-around;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.download-app-card h3 {
  color: #4120ce;
}

.qr-code-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.download-app-card-description {
  color: #5a5463;
  margin: 10px 0;
}

.download-app-card-content {
  padding: 20px;
}

.download-app-card-content h3 {
  margin: 10px 0;
}

.feature-list {
  list-style-type: disc;
  padding-left: 20px;
  margin: 2rem 0;
  color: #4120ce;
}

.feature-list li {
  margin-bottom: 8px;
  color: #4120ce;
}

.locals-list-title {
  margin: 20px 0;
}

.locals-done {
  color: #4120ce;
  display: flex;
  align-items: center;
}
