.settings-card {
    display: flex;
    width: auto;
    height: auto;
    min-height: 160px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    align-items: center;
    box-sizing: border-box;
    text-align: left;
    text-transform: none; // Remove a transformação de texto padrão dos botões do MUI
    justify-content: flex-start;

    &.MuiButton-root { // Estiliza especificamente o botão do MUI
        padding: 0; // Remove o padding padrão do botão do MUI
    }
}

.settings-card-icon {
    margin-bottom: 1rem;
}

.settings-card-title {
    color: #292929;
    font-size: 16px;
    font-family: "Inter-bold";
}

.settings-card-subtitle {
    color: #404040;
    font-size: 12px;
    font-family: "Inter-Regular";
}

.settings-card-content {
    display: flex;
    flex-direction: column;
    padding: 2rem;
}

