@use "../4-helpers" as *;

.table-header {
  width: 100%;
  @include themed() {
    background-color: t("tb-bg");
  }
}

.header-summary {
  padding: 0;
  flex-direction: row;
}

.header-summary-content {
  margin: 0;
  padding: 2rem;
  justify-content: space-around;
  align-items: center;
  gap: 2.5rem;
}

.header-details {
  display: flex;
  padding: 2.4rem 0 2.4rem 4.8rem;
  @include themed() {
    background-color: t("tb-header-bg");
  }
}

.c-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;

  &__header {
    background-color: #f4f4f4;
    font-weight: bold;
    text-align: center;
    color: #333333;
    font-size: 1.4rem;
    width: 100%;
  }

  &__row {
    border-bottom: 1px solid #e0e0e0;
    width: 100%;

    &:hover {
      background-color: #f8f8f8;
    }
  }

  &__cell,
  &__header-cell {
    padding: 1.2rem;
    text-align: center;
    font-size: 1.3rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__action-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
}

.c-table-error {
  display: flex;
  flex-direction: column;
  height: 60rem;
  width: 40rem;
  align-items: center;
  justify-content: center;
  @include themed() {
    background-color: t("tb-bg");
  }

  &--no-result {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 10rem;
    @include themed() {
      background-color: t("tb-bg");
    }
  }
}

.c-table-loading {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 0 2rem;
  @include themed() {
    background-color: t("tb-bg");
  }
}

.table-icon-wrapper {
  width: 12rem;
  height: 12rem;
  @include themed() {
    background-color: t("icon-table-bg");
  }

  &--no-result {
    width: 8rem;
    height: 8rem;
    margin-right: 2rem;
  }
}

.table-error {
  &__title {
    text-align: center;
    margin: 1.2rem 0;
  }

  &__text {
    text-align: center;
    margin-bottom: 2.4rem;
  }
}

.c-status {
  padding: 0.8rem 1.6rem;
  border-radius: 2rem;
  &--active {
    @include themed() {
      color: t("tb-status-text");
      background-color: t("tb-status-active");
    }
  }

  &--inactive {
    @include themed() {
      color: t("tb-status-text");
      background-color: t("tb-status-inactive");
    }
  }
}

.c-input-search {
  display: flex;
  flex-grow: 1;
  min-width: 10rem;
  max-width: 100%;
  align-items: center;
  &--avatar {
    width: 6.3rem;
  }
}

.c-skeleton-table-pagination {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  padding: 2rem 0;
}

.skeleton-table-line {
  height: 4rem;
  margin-bottom: 2.4rem;
  border-radius: 0.4rem;
}

.skeleton-table-circle-btn {
  height: 3.6rem;
  width: 3.6rem;
  border-radius: 50%;
  margin: 0 0.4rem;
}

.skeleton-table-rect-btn {
  height: 4rem;
  width: 19.2rem;
  border-radius: 0.4rem;
}

.table-search-width {
  width: 1rem;
}

.c-action-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 20rem;
}

.date-picker-container {
  display: flex;
  gap: 1rem 0.5rem;
  align-items: center;
  justify-content: left;
  height: 4rem;

  @media (max-width: 768px) {
    flex-direction: column;
    margin: 0 0 11rem 0;
    gap: 0;
  }
}

.input-container {
  position: relative;
  display: inline-block;
}

.date-input {
  width: 18rem;
  height: 4rem;
  padding: 1.2rem 2.4rem;
  border-radius: 4px;
  border: 0.1rem solid #4120ce;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  margin-top: 0.7rem;
}

.date-input::placeholder {
  color: #4120ce;
  font-size: 15px;
  font-style: normal;
  line-height: 100%;
  text-align: center;
  font-family: "Inter-Bold", sans-serif;
}

.date-input-medium {
  width: 25.2rem;
  height: 4rem;
  padding: 1.2rem 2.4rem;
  border-radius: 4px;
  border: 0.1rem solid #4120ce;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  margin-top: 0.7rem;
}

.date-input-medium::placeholder {
  color: #4120ce;
  font-size: 15px;
  font-style: normal;
  line-height: 100%;
  text-align: center;
  font-family: "Inter-Bold", sans-serif;
}

.clear-button {
  position: absolute;
  right: 1rem;
  top: 49%;
  transform: translateY(-50%);
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
}

.close-button {
  height: 25px;
}

.separator {
  display: flex;
  align-items: center;
  justify-content: center;

  color: #292929;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.titleSeparator {
  display: flex;
  align-items: center;
  justify-content: left;

  color: #292929;
  min-width: 12rem;

  font-size: 16px;
  font-style: normal;
  line-height: 120%;

  font-family: "Inter-Bold", sans-serif;
}

.calendar-icon {
  position: absolute;
  right: 75px;
  top: 54%;
  transform: translateY(-50%);
  cursor: pointer;
}

.column-date-start {
  border: 1px solid black;
  padding: 0;
}

.datetime,
.date_start,
.date_end,
.title,
.alert_type_name,
.risk_level {
  font-size: 1.3rem;
}

.rdt_TableBody {
  max-width: 100%;
}
.rdt_TableRow {
  max-width: 100%;
}

.rdt_TableCell {
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
  text-align: left !important;
}

.row-title {
  width: 100% !important;
}

.date_start {
  margin: 0 !important;
  padding: 0 !important;
}

.rdt_TableCol {
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
}

.rdt_TableCol_Sortable {
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
}

.aler_tx_name {
  font-size: 1.4rem !important;
  font-family: $font-primary-regular !important;
}

.nive_tx_name {
  font-size: 1.4rem !important;
  font-family: $font-primary-regular !important;
}

.table-container {
  width: 100%;
  overflow-x: auto;
}

.content-form-alert {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100% !important;
  gap: 1rem;

  @media (max-width: 1339px) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.content-form-alert .titleSeparator {
  @media (max-width: 768px) {
    text-align: left !important; /* Alinha o texto à esquerda */
    margin-left: 0 !important; /* Remove qualquer margem que o centralize */
    width: 100%; /* Garante que o título ocupe toda a largura disponível */
    display: block; /* Garante o comportamento em bloco */
  }
}

.content-form-alert > button {
  flex-grow: 2;
  min-width: fit-content;

  @media (max-width: 768px) {
    width: 18rem !important;
  }
}

.table-container {
  width: 100%;
  overflow-x: auto;

  .rdt_Table {
    width: 100%;
  }
}

.c-main-content--table {
  @media (max-width: 1114px) {
    padding: 1rem;
  }

  @media (max-width: 768px) {
    padding: 1rem;
  }
}

.search-table-container {
  width: 100%;
  height: 4rem;
  padding: 1.2rem 1rem;
  margin-right: 15px;
  border-radius: 4px;
  border: 0.1rem solid #4120ce;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.7rem;
  background-color: white;

  // @media (max-width: 1339px) {
  //   width: 51.5rem;
  //   margin: 1rem 0 0 0;
  // }

  @media (max-width: 900px) {
    width: 53rem;
    margin: 1rem 0 0 0;
  }

  @media (max-width: 768px) {
    width: 18rem;
  }
}

.search-table-content {
  margin: 0;
  padding: 0;
  background-color: transparent !important;
}

.search-table :hover {
  background-color: transparent !important;
}

.hide-columns-container {
  width: max-content;
  height: 4rem;
  margin-right: 15px;
  padding: 1.2rem 1rem;
  border-radius: 4px;
  border: 0.1rem solid #4120ce;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.7rem;
  margin-left: 0;
  background-color: white;

  // @media (max-width: 1339px) {
  //   width: 51.5rem;
  //   margin: 1rem 0 0 0;
  // }

  @media (max-width: 900px) {
    width: 53rem;
    margin: 1rem 0 0 0;
  }

  @media (max-width: 768px) {
    width: 18rem;
    margin: 1rem 0 0 0;
  }
}

.hide-columns-content {
  margin: 0;
  padding: 0;
  background-color: transparent !important;
}

.hide-columns-container :hover {
  background-color: transparent !important;
}
