.c-login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f1eeff;
}

.banner-login {
  width: 50vw !important;
  height: 100vh !important;

  @media (max-width: 768px) {
    width: 100vw !important;
  }
}

.c-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.c-right-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}
.c-right-login img {
  max-width: 30%;
}

.c-left {
  width: 50vw;
  height: 100vh;
  position: relative;
  background-image: url("../../assets/img/grafismos.png"),
    url("../../assets/img/cittua_login_bg.png");
  background-repeat: no-repeat, no-repeat;
  background-size: cover, cover;
  background-position: -25rem 0, -10rem 0;
}

.c-login-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: min(90%, 500px);
  padding: 3rem;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    max-height: 100%;
    max-width: 90%;
  }

  @media (min-width: 1920px) {
    max-width: 600px;
  }

  @media (min-width: 2560px) {
    max-width: 800px;
  }
}

.c-recaptcha {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  height: 10rem;
  margin: 1rem 0;
}

.logo-img {
  width: 20rem;
  height: 5.5rem;
}

.c-tenant-selection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.selection-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 43.9rem;
}

.tenant-selection-title {
  margin-top: 1rem;
  text-align: center;
}

.login-form {
  display: flex;
  flex-direction: column;
  width: 100%;

  .c-input {
    margin-bottom: 1.5rem;
  }
  .login-checkbox {
    margin: 1.5rem 0;
  }
}

.forgot-password {
  display: flex !important;
  justify-content: flex-start !important;
}

.login-form__title {
  text-align: left;
  margin: 2rem 0;
  font-weight: 700;
  font-size: 3.5rem;
  line-height: 3.5rem;

  @media (max-width: 1366px) {
    font-size: 3rem;
    padding: 3rem 10rem 0 0;
  }
}

// General

.background-create-account {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  padding: 2rem;
  justify-content: center;
  gap: 2rem;
  background-color: #f1eeff;

  .left-call-to-action {
    flex: 1;
    max-width: clamp(300px, 55%, 800px);
    padding: 3rem 0 0 5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;

    & img {
      width: 182px;
    }
  }

  .card-internal {
    flex: 1;
    max-width: clamp(300px, 40%, 600px);
    background-color: #fff;
    padding: 3rem;
    border-radius: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 768px) {
    flex-direction: column;

    .left-call-to-action,
    .card-internal {
      max-width: 100%;
    }

    .left-call-to-action {
      padding: 0;
    }
  }
}

.selection-content {
  max-width: clamp(300px, 80%, 43.9rem);
}
.mobile-cta-container {
  display: none;
  margin: 16px;

  img {
    width: 182px;
    margin-bottom: 16px;
  }

  .text-background {
    background: #f5f5f5;
    border-radius: 12px;
    padding: 20px;
  }
}
.mobile-title {
  font-size: 1.2rem;
  margin-top: 16px;
}

@media screen and (max-width: 768px) {
  .mobile-cta-container {
    display: block;
  }

  .left-call-to-action {
    padding: 0;
  }

  /* Hide the original desktop version */
  .background-create-account > .left-call-to-action {
    display: none;
  }

  .mobile-title {
    width: 100%;

    .form-title {
      width: 100%;
      padding: 0;

      &:first-child {
        margin-bottom: 1rem;
      }

      &:last-child {
        margin-top: 1rem;
      }
    }
  }
}

.form-title {
  text-align: left;
  padding: 0 0 3rem 0;
  font-weight: 700;
  font-size: 4rem;
  line-height: 4.2rem !important;

  @media (min-width: 1400px) {
    font-size: 3.5rem;
    line-height: 3.5rem;
    padding: 3.6rem 0 1rem 0;
  }

  @media (max-width: 768px) {
    font-size: 2rem;
    // padding: 0 0 3rem 0;
  }
}

.internal-form {
  margin-bottom: 2rem;

  > * + * {
    margin-top: 2rem;
  }

  @media (max-width: 768px) {
    justify-content: center;
  }
}

.form-text-type0 {
  max-width: 60%;
  margin: 4rem 0 1rem 0rem;

  @media (max-width: 768px) {
    margin: 0;
    padding: 0;
    max-width: 100%;
    color: #4120ce;
  }
}

.badge-number {
  background-color: #4120ce;
  color: #fff;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.form-text-type1 {
  color: #292929;
  font-size: 1.9rem;
  line-height: 2.5rem;
  font-weight: 500;
  margin: 1.2rem 0;
}

.form-text-type2 {
  color: #4120ce;
  font-size: 1.5rem;
  line-height: 2.5rem;
  font-weight: 700;
  max-width: 80%;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.phone-input-group {
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: 768px) {
    flex-direction: row;

    .react-select {
      min-width: 120px;
      flex-shrink: 0;
    }

    input {
      flex: 1;
      min-width: 0;
    }
  }
}

.country-flag {
  width: 20px;
  height: 15px;
  margin-right: 10px;
}

.select-option {
  display: flex;
  align-items: center;
  padding: 5px;
}

.select-single-value {
  display: flex;
  align-items: center;
  width: 80px;
}

.select-single-value span {
  font-size: 1.5rem;
}

.terms-internal-pages {
  margin-top: 3rem !important;
  margin-bottom: 0.8rem !important;
}

// O react-select tem estilização padrão, estas abaixo anulam algumas delas para manter o padrão da aplicação
.react-select__input-container {
  display: none;
}

.react-select__indicator-separator {
  display: none;
}

.react-select__dropdown-indicator {
  padding: 4px;
}

.react-select__control {
  border: 0.1rem solid #e0e0e0;
  transition: none;
}

.react-select__control:hover {
  border: 0.1rem solid #e0e0e0;
}

.input {
  height: 40px;
  min-height: 40px;
}

.react-select__control {
  height: 40px;
  min-height: 40px !important;
}

.react-select__value-container {
  height: 40px;
  padding: 0 8px !important;
}

.react-select__indicators {
  height: 40px;
}

.react-select__input {
  height: 40px;
}

.existing-account {
  font-size: 2rem;

  & a {
    color: #4120ce;
    font-weight: 700;
    text-decoration: none;
  }
}
