@use '../4-helpers' as *;

.c-settings {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
    height: 100%;
	justify-content: center;
	border-radius: .4rem;
    padding: 7rem 8.5rem 7rem 8.5rem;
	@include themed() {
		border: .1rem solid t('primary-border');
		background-color: t('primary-bg');
	}
}

.c-options {
	display: flex;
	flex-direction: row;
	width: 100%;
    height: 100%;
	align-items: flex-start;
	justify-content: space-between;
	border-radius: .4rem;
	gap: 8rem;
}

.c-options-col {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 5rem;
}

.settings-title {
    color: #000000;
    font-size: 34px;
    font-family: "Inter-bold";
	padding-bottom: 1rem;
}

.settings-subtitle {
    color: #000000;
    font-size: 14px;
    font-family: "Inter-regular";
	padding-bottom: 5rem;
}